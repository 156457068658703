<template>
  <v-expansion-panel
    v-if="!isNew"
    @click="handleExpand"
    style="padding-bottom:0px;"
  >
    <v-expansion-panel-header>{{ $t('booking.schedules.title', locale)}}</v-expansion-panel-header>
    <v-expansion-panel-content
      class="schedules-list-main"
      style="padding: 24px 10px 10px 10px;"
    >
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      />
      <div v-if="schedules">
        <v-btn
          color="primary"
          block
          @click="handleAddSchedule"
        >
          {{ $t('common.add', locale) }}
        </v-btn>
        <activity-event-form
          v-if="editedScheduleType === 'specific'"
          :locale="locale"
          :editedItem="editedItem"
          :onClose="handleClose"
          :onSave="handleSaveSpecific"
          :dialog="showForm"
        />
        <activity-schedule-form
          v-if="editedScheduleType === 'recurrence'"
          :locale="locale"
          :editedItem="editedItem"
          :onClose="handleClose"
          :onSave="handleSaveRecurrence"
          :dialog="showForm"
        />
        <!--schedule-form
          v-else
          :activityID="editedActivityID"
          :locale="locale" 
          :editedScheduleID="editedScheduleID"
          :onClose="handleCloseScheduleForm"
          :activitySeats="activitySeats"
        /-->
        <custom-table
          style="margin-top: 40px"
          :loading="loading"
          :headers="[
            { text: $t('workspace.name', locale), value: 'ident' },
            { text: '', value: 'hide', align: 'end' },
            { text: '', value: 'actions', align: 'end' },
          ]"
          :onGetData="handleGetData"
          :onEditItem="editedScheduleType === 'recurrence' ? handleEditSchedule : null"
          :onDeleteItem="editedScheduleType === 'recurrence' && 1===2 ? handleDeleteSchedule : null"
          :onHideItem="editedScheduleType === 'recurrence' ? handleHideSchedule : null"
          :tableData="schedules"
          :startIndex="startIndex"
          :itemsPerPage="itemsPerPage"
          :count="count"
          hideAddOnNoData
          hideHeader
        />
        <br/>
      </div>
      <edit-schedule-form
        :scheduleID="editedScheduleID"
        :onClose="handleCloseScheduleForm"
        :locale="locale"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
//import ScheduleForm from './scheduleForm/Index'
import CustomTable from '@/components/customTable/Index'
import ActivityEventForm from '@/components/ActivityEventForm'
import ActivityScheduleForm from '@/components/activityScheduleForm/Index'
import EditScheduleForm from './EditScheduleForm'
export default {
  components: {
    //ScheduleForm,
    ActivityEventForm,
    ActivityScheduleForm,
    CustomTable,
    EditScheduleForm,
  },
  props: {
    locale: {
      type: String,
      default: null,
    },
    editedActivityID: {
      type: String,
      default: null,
    },
    editedScheduleType: {
      type: String,
      required: true,
    },
    activitySeats: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    schedules: null,
    count: 0,
    startIndex: 0,
    itemsPerPage: 20,
    search: '',
    loading: false,
    editedScheduleID: null,
    editedItem: null,
    showForm: false,

    editedScheduleForm: null,
  }),
  computed: {
    isNew () {
      return this.editedActivityID === 'new'
    },
  },
  methods: {
    handleExpand () {
      if (this.editedActivityID && !this.schedules) this.handleGetData(0)
    },
    handleGetData (startIndex) {
      this.loading = true
      this.startIndex = startIndex
      if (this.editedScheduleType === 'recurrence')
        api.getAll ('booking', `v1/private/activities/${this.editedActivityID}/schedules`, this.startIndex, this.itemsPerPage, this.search ? this.search : '')
          .then(response => {
            this.count = response.count
            this.schedules = response.data.map(item => {
              let name = `${item.IsCustomLimit ? '[✏] ' : ''}${utils.checkDate(item.StartDate)} → ${item.EndDate ? utils.checkDate(item.EndDate) : '∞'}`.concat(item.Alias ? ` (${item.Alias}) ` : '')
              if (item.Type === 'byDay') {
                const aux = JSON.parse(item.Dates)
                name = aux.length > 0 ? ''.concat(` ∞ (${item.Alias})`) : item.Alias
              }

              return {
                        ID: item.ID,
                        Name: name,
                        Hide: item.Hide
                      }
            })
            this.loading = false
          })
      else
        api.getAll ('booking', `v1/private/activities/${this.editedActivityID}/events`, this.startIndex, this.itemsPerPage, this.search ? this.search : '')
          .then(response => {
            this.count = response.count
            this.schedules = response.data.map(item => {
              return {
                        ID: item.ID,
                        Name: `${ item.Date.split('T').shift() } → ${utils.convertTime(item.Time)} - ${utils.convertTime(item.Duration+item.Time)}`,
                      }
            })
            this.loading = false
          })
    },
    handleCloseScheduleForm () {
      this.handleGetData(0)
      this.editedScheduleID = null
    },
    handleAddSchedule () {
      this.editedScheduleID = 'new'
      if (this.editedScheduleType === 'specific') {
        this.editedItem = {
          ID: utils.getNewID(),
          Date: utils.checkDate(new Date()),
          Time: 480,
          Duration: 90,
          IsNew: true,
        }
      }
      this.showForm = true
    },
    handleEditSchedule (v) {
      if (this.editedScheduleID === v) {
        this.editedScheduleID = null
        setTimeout(() => { this.editedScheduleID = v }, 1000)
      } else this.editedScheduleID = v
    },
    handleHideSchedule (id, hide) {
      this.loading = true
      api.put ('booking', `v1/private/activities/${this.editedActivityID}/schedules/${id}/hide`, { Hide: hide ? false : true})
        .then(() => {
          this.handleGetData(0)
          this.loading = false
        })
    },
    handleDeleteSchedule (v) {
      this.loading = true
      api.deleteItem ('booking', `v1/private/activities/${this.editedActivityID}/schedules/`, v)
        .then(() => {
          this.handleGetData(0)
          this.loading = false
        })
    },
    handleClose () {
      this.showForm = false
    },
    handleSaveSpecific (v) {
      this.loading = true
      api.addItem ('booking', `v1/private/activities/${this.editedActivityID}/events`, v)
        .then(() => {
          this.handleGetData(0)
          this.handleClose()
          this.loading = false
        })
    },
    handleSaveRecurrence (v) {
      this.loading = true
      api.addItem ('booking', `v1/private/activities/${this.editedActivityID}/schedules`, v)
        .then(() => {
          this.handleGetData(0)
          this.handleClose()
          this.loading = false
        })

    }
  },
}
</script>
<style scoped>
.schedules-list-main {
  padding-top: 15px;
  min-height: 70px;
}
</style>

