<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card style="padding-top: 20px">
        <!--v-card-title>
          <span class="text-h5">{{ $t(`booking.event`, locale) }}</span>
        </v-card-title-->
        <v-card-text style="padding:0">
          <v-container>
            <v-row
              v-if="editedItem"
            >
              <v-col
                cols="12"
                sm="6"
                style="padding: 0 0 0 23px;"
              >
                <v-date-picker
                  v-model="editedItem.Date"
                  :min="minDate"
                  :first-day-of-week="0"
                  :locale="locale"
                  style="width:100%"
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                style="padding-right:40px;"
              >
                <interval-times
                  style="float:right;"
                  :label="`${$t('booking.scheduleForm.startTime')}:`"
                  v-model="editedItem.Time"
                />
                <interval-times
                  style="float:right;"
                  :label="`${$t('booking.scheduleForm.duration')}:`"
                  v-model="editedItem.Duration"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="padding: 0 20px 20px 0">
          <v-spacer/>
          <v-btn
            color="default"
            text
            @click="() => { onClose() }"
          >
            {{ $t('common.close', locale) }}
          </v-btn>
          <v-btn
            :elevation="0"
            color="primary"
            @click="handleSave"
          >
            {{ $t('common.save', locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
//import api from '@/services/api'
import IntervalTimes from '@/components/intervalTimes/Index'
import utils from '@/services/utils'
export default {
  components: {
    IntervalTimes,
  },
  props: {
    editedItem: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    onSave: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    showDialog: false,
    loading: false,
    minDate: utils.checkDate(new Date()),
  }),
  watch: {
    dialog () {
      this.showDialog = this.dialog
    },
  },
  mounted () {
    this.showDialog = this.dialog
  },
  methods: {
    handleSave () {
      this.onSave(this.editedItem)
    },
  },
}
</script>

