<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card style="padding-top: 20px">
        <!--v-card-title>
          <span class="text-h5">{{ $t(`booking.event`, locale) }}</span>
        </v-card-title-->
        <v-card-text style="padding:0">
      <schedule-recurrence-step 
        :pos="pos"
        :schedule="schedule"
        :onChange="handleChangeSchedule"
        :locale="locale"
      />
        </v-card-text>
        <v-card-actions style="padding: 0 20px 20px 0">

          <v-spacer />
      <v-btn 
        text 
        @click="onClose"
        style="margin-right:10px"
      >
        {{$t('common.cancel', locale)}}
      </v-btn>
      <v-btn 
        v-if="pos > 2"
        color="secundary"
        @click="handleChangeStep(-1)"
        style="margin-right:10px"
      >
        {{$t('common.back', locale)}}
      </v-btn>
      <v-btn
        v-if="pos === 6"
        color="primary"
        @click="handleSave"
      >
        {{$t('common.save', locale)}}
      </v-btn>
      <v-btn
        v-else
        color="primary"
        @click="handleChangeStep(1)"
      >
        {{$t('common.next', locale)}}
      </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
const defaultSchedule = {
  Alias: null,
  Type: 'byWeek',
  Dates: [],
  StartDate: utils.checkDate(new Date()),
  EndDate: null,//utils.checkDate(new Date()),
  Alternation: 1,
  StartTime: 480,
  Interval: 30,
  AllDay: false,
  Duration: 30,
  Months: [],
  Timetable: {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: []
  },
}
//import api from '@/services/api'
import ScheduleRecurrenceStep from './ScheduleRecurrenceStep'
import utils from '@/services/utils'
export default {
  components: {
    ScheduleRecurrenceStep,
  },
  props: {
    editedItem: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    onSave: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    pos: null,
    showDialog: false,
    loading: false,
    schedule: null,
    minDate: utils.checkDate(new Date()),
  }),
  computed: {
    languages () {
      return this.$store.state.app.bookingLanguages
    },
    workspaceID () {
      return utils.getWorkspace('booking')
    },
  },
  watch: {
    dialog () {
      if (this.dialog) {
        this.pos = 2
        this.schedule = JSON.parse(JSON.stringify(defaultSchedule))
      }
      this.showDialog = this.dialog
    },
  },
  mounted () {
    this.showDialog = this.dialog
  },
  methods: {
    handleChangeStep (v) {
      this.pos += v
    },
    handleChangeSchedule(v) {
      this.schedule = v
    },
    handleSave () {
      this.onSave(this.schedule)
    },
  },
}
</script>

